import axios from 'axios';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import {Button, theme, brandFont} from '@partssourceinc/react-ui-core';
import PageMetaData from 'components/PageMetaData';
import * as NetworkStore from 'stores/Network';
import * as DashboardStore from 'stores/Dashboard';
import * as UserStore from 'stores/User';
import * as Socket from 'stores/socket';

import styled from 'styled-components';
import {BrComponent} from '@bloomreach/react-sdk';
import { AuthHandlerContext } from 'contexts/AuthHanderContext';

const Title = styled.h1`
    font-weight: 400 !important;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const PrivacyPolicyContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`;

const Footer = styled.div`
    margin-top: 24px;
    margin-bottom: 96px;
    padding: 16px 0px 0px 0px;
    border-top: 1px solid ${theme.grey3};
    text-align: right;

    @media (max-width: ${theme.screenXsMax}) {
        margin-top: 24px;
        padding: 16px 0px 0px 0px;
        border-top: 1px solid ${theme.grey3};
        position: relative;
        bottom: 0px;
    }

    >:not(:first-child) {
        margin-left: .25rem;
    }

    >:not(:last-child) {
        margin-right: .25rem;
    }
`;

const CancelLink = styled.a`
    ${brandFont('14px')};
    color: ${theme.grey3};
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    text-transform: uppercase;
    margin-right: 20px !important;
    outline: none;

    &:hover {
        color: ${theme.grey3};
        text-decoration: underline;
        outline: none;
    }

    &:focus {
        color: ${theme.grey3};
        text-decoration: underline;
        outline: none;
    }

    &:active:focus {
        color: ${theme.grey3};
        text-decoration: underline;
        outline: none;
    }
`;

@connect((state) => ({network: state.network, system: state.system}), _.assign({}, NetworkStore.actionCreators, DashboardStore.actionCreators, UserStore.actionCreators, Socket.actionCreators))
@withRouter
export default class Eula extends React.Component {
    static contextType = AuthHandlerContext;

    static propTypes = {
        ...ReactRouterPropTypes,
        ...NetworkStore.ActionShape,
        ...DashboardStore.ActionShape,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            eula: null,
        };
        this.acceptEula = this.acceptEula.bind(this);
        this.declineEula = this.declineEula.bind(this);
    }

    acceptEula() {
        const {
            network: {tokenInfo: {userId, firstName, lastName, subscriptionId}},
            history,
            setUserId,
            markEulaAccepted,
            system: {oktaAuthentication}
        } = this.props;
        axios.post('/ShoppingService/api/v1/account/policies-acceptance', {agree: true}).then((response) => {
            setUserId(userId, firstName, lastName, subscriptionId, response.data.passwordExpired, false);
            markEulaAccepted();
            if (oktaAuthentication && response.data.passwordExpired)
                history.push('/changepassword');
            else
                history.push('/');
        });
    }

    // TODO: We might want to block navigation away from this page - logoff the user if he tries to do it
    declineEula() {
        const {logout, history, stopNotifications, disconnectWebsocket} = this.props;
        disconnectWebsocket();
        stopNotifications();
        logout();
        this.context.triggerLogout();
        history.push('/login');
    }

    render() {
        const {network: {isLoggedIn}} = this.props;

        return (<div style={{marginTop: '48px'}}>
            <PageMetaData
                title="End User License Agreement"
                pageType="other"
                trackAnalytics={false} />
            <div style={{margin: '-16px auto', maxWidth: '768px', minWidth: '280px', marginBottom: '24px', paddingTop: '24px'}}>
                <PrivacyPolicyContainer>
                    <BrComponent path={'main'} />
                </PrivacyPolicyContainer>
            </div>

            {isLoggedIn ?
                <Footer>
                    <CancelLink onClick={this.declineEula} tabIndex="0">Decline</CancelLink>
                    <Button onClick={this.acceptEula} tabIndex="0" className="button button-primary" style={{width: '100px', marginBottom: '10px', marginRight: '20px'}}>Accept</Button>
                </Footer> :
                null
            }
        </div>)
    }
}
